@font-face {
  font-family: 'Cera PRO';
  font-style: normal;
  font-weight: 100 300;
  src: url('cera-pro-light.otf') format('opentype');
}

@font-face {
  font-family: 'Cera PRO';
  font-style: normal;
  font-weight: 400 500;
  src: url('cera-pro-regular.otf') format('opentype');
}

@font-face {
  font-family: 'Cera PRO';
  font-style: normal;
  font-weight: 600;
  src: url('cera-pro-medium.otf') format('opentype');
}

@font-face {
  font-family: 'Cera PRO';
  font-style: normal;
  font-weight: 700 1000;
  src: url('cera-pro-bold.otf') format('opentype');
}
