@import 'src/styles/variables.scss';

.button {
  width: 70px;
  height: 70px;

  border-radius: 50% !important;
  border: 3.5px solid $selected-border !important;
}

.score {
  font-size: 2.7rem;
  line-height: 1;
  height: 30px;
  font-weight: 300;
}

.add_image {
  max-height: 90% !important;
}

.label {
  font-size: 0.8rem;
}
