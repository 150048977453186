@import 'src/styles/variables.scss';

.container {
  width: var(--size);
  height: var(--size);
  background-color: #e9ecef;
  color: #212529;
  border: 2px solid $selected-border;
  font-size: calc(var(--size) * 0.37);
  overflow: hidden;
}
