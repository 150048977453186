@import './variables.scss';

// theme-color-variables
$light: $golden-accent;
$primary: $olive-gray;

// optional features
$enable-shadows: true;
$enable-gradients: true;
$enable-transitions: true;
$enable-rfs: false;

// body
// https://wine-country-live.slack.com/archives/D04K6PB628L/p1673983465515289
$body-bg: #f9f6f3;
$body-color: #222222;

// Typography
$font-size-root: $default-text-size;
$font-family-sans-serif: 'Cera PRO', system-ui, -apple-system, 'Segoe UI',
  Roboto, 'Helvetica Neue', 'Noto Sans', 'Liberation Sans', Arial, sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;
$font-size-base: 1rem;
$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.75 !default;
$h3-font-size: $font-size-base * 1.5 !default;
$h4-font-size: $font-size-base * 1.25 !default;

// buttons
$btn-disabled-opacity: 0.35;

// navbar
$navbar-light-color: #000;

// popovers
$popover-font-size: $default-text-size;
$popover-max-width: 350px;
$popover-header-bg: none;
//$popover-header-padding-y: 1rem;
$popover-arrow-width: 2rem;
$popover-arrow-height: 1rem;
$popover-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

// modals
$modal-content-border-radius: 5px;
$modal-inner-padding: 1.5rem;
$modal-content-bg: #f9f6f3;
$modal-dialog-margin: 1.5rem;
$modal-md: 350px;

// import bootstrap styles
@import '~bootstrap/scss/bootstrap.scss';

// bootstrap classes overwrites
// required to show hamburger icon outside navbar
.navbar-toggler-icon {
  --#{$prefix}navbar-toggler-icon-bg: #{escape-svg(
      $navbar-light-toggler-icon-bg
    )};
}

.popover {
  --#{$prefix}popover-font-size: #{$default-text-size};
}
