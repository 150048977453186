$slider-height: 450px;
$slider-width: 250px;

$track-position: 70%;
$track-width: 20px;

$slider-colors: (
  'white': (
    'empty': #ead178,
    'full': rgba(#f3a31a, 0.8),
  ),
  'red': (
    'empty': #c15651,
    'full': #5a0041,
  ),
  'rose': (
    'empty': #ecc9c1,
    'full': #de8a8a,
  ),
  'sparkling-rose': (
    'empty': #ecc9c1,
    'full': #de8a8a,
  ),
  'sparkling-white': (
    'empty': #efdcb7,
    'full': #d3b365,
  ),
);

$simple-mark-size: 4px;

$thumb-width: 40px;
$thumb-height: 10px;
$thumb-bg: #eee8da;
$thumb-border: #9aa384;
