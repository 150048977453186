.container {
  position: relative;
  background-size: cover;
  background-position: center;

  > * {
    position: relative;
  }
}

.container::before {
  content: '';
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.7);
}
