@import 'src/styles/variables.scss';

.list {
  --size: 25px;
  --margin: 10px;
  color: $olive-gray;
  list-style: none;
  counter-reset: words-to-taste-by-counter;
  margin-left: calc(var(--margin) + var(--size));

  li {
    counter-increment: words-to-taste-by-counter;
    position: relative;

    &::before {
      position: absolute;
      content: counter(words-to-taste-by-counter);
      left: calc(-1 * (var(--size) + var(--margin)));
      width: var(--size);
      height: var(--size);
      background-color: #ece6d6e5;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      color: rgba(#5d5c4d, 0.7);
      border-radius: calc(var(--size) / 2);
    }
  }
}
