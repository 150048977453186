@use 'sass:math';
@import './variables.scss';

body {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Avoid Chrome to see Safari hack */
@supports (-webkit-touch-callout: none) {
  body {
    /* The hack for Safari */
    height: -webkit-fill-available;
  }
}

// show in phone size when browsing from desktop
@media only screen and (min-width: 600px) {
  body {
    background-color: black;
  }

  #root {
    background-color: $body-bg;
    width: 100%;
    max-width: 390px;
    max-height: 844px;
    margin: auto;
    border-radius: 5px;
    overflow: hidden;
  }
}

// shadow for images content

.wcl-image-shadow {
  filter: drop-shadow(0px 2.4px 2.4px rgba(0, 0, 0, 0.2));
}

// text-size

.wcl-text-size {
  &-sm1 {
    font-size: math.div($default-text-size, 6) * 5;
  }

  &-sm2 {
    font-size: math.div($default-text-size, 3) * 2;
  }

  &-lg1 {
    font-size: math.div($default-text-size, 6) * 7;
  }

  &-lg2 {
    font-size: math.div($default-text-size, 6) * 8;
  }
}

// blur modal backdrop
body.modal-open #root {
  filter: blur(1px);
}
