@import 'src/styles/variables.scss';

.container {
  background-color: $drawing-black;
  background-image: url('./vineyard-background.jpg');
  background-size: cover;
  background-position: center;
}

.topLogoContainer {
  padding: 1rem;
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
}

.titleContainer {
  color: $olive-gray;
}
