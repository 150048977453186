@use 'sass:math';
@use 'sass:map';
@import './variables.scss';

.slider {
  height: $slider-height;
  width: $slider-width;
  max-height: 80vh;
}

@each $color, $values in $slider-colors {
  .#{$color}-empty-bar {
    --empty-bg-start: #{map.get($values, 'empty')};
    --empty-bg-end: #{rgba(map.get($values, 'empty'), 0.2)};
  }

  .#{$color}-full-bar {
    --full-bg: #{map.get($values, 'full')};
  }
}

.empty-bar {
  position: relative;
  background-color: var(--empty-bg);
  background: linear-gradient(
    180deg,
    var(--empty-bg-start) 0,
    var(--empty-bg-end) #{$slider-height}
  );
  left: calc($track-position - $track-width / 2);
  width: $track-width;
  border-radius: 4px;
}

.full-bar {
  composes: empty-bar;
  background-color: var(--full-bg);
  background: linear-gradient(
    0deg,
    white 0,
    white calc(#{math.div($slider-height, 15) * 2} - 1px),
    var(--full-bg) calc(#{math.div($slider-height, 15) * 2} - 1px),
    var(--full-bg) 100%
  );
}

.thumb {
  width: $thumb-width;
  height: $thumb-height;
  left: calc($track-position - 20px);
  background: $thumb-bg;
  border: 4px solid $thumb-border;
  box-shadow: 0px 2.24736px 2.24736px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}
