@use 'sass:math';
@import './variables.scss';

$label-spacing: 20px;
$mark-size: 8px;
$line-width: 60px;

.container {
  left: 0;
  right: 0;
}

.line {
  position: absolute;
  left: calc($track-position - ($line-width / 2));
  width: $line-width;
  border-bottom: 1px dashed #505050;
  bottom: 4px;
}

.mark {
  width: $mark-size;
  height: $mark-size;
  border: 1.5px solid #9aa384;
  background-color: #fff;
  cursor: pointer;
  border-radius: $track-position;
  vertical-align: middle;
  left: calc($track-position - $mark-size / 2);
  position: absolute;
  bottom: 0;
}

.label {
  font-size: 14px;
  position: absolute;
  right: calc(
    #{100% - $track-position} + #{math.div($thumb-width, 2) + $label-spacing}
  );
  bottom: 0;
  line-height: 0.9;
}

.value {
  font-size: 14px;
  position: absolute;
  left: calc(
    #{$track-position} + #{math.div($thumb-width, 2) + $label-spacing}
  );
  bottom: 0;
  line-height: 0.9;
}

.labelSelected {
  font-weight: bold;
}

.simpleMark {
  width: $simple-mark-size;
  height: $simple-mark-size;
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
  left: calc($track-position - $simple-mark-size/2);
}
