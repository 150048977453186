@import 'src/styles/variables.scss';

.logoContainer {
  border: 2px solid $drawing-black;
  padding: 3rem 1rem;
}

.glassContainer {
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $body-bg;
}

.messageContainer {
  bottom: 0;
  left: 50%;
  //transform: translate(-50%, 60%);
  background-color: $body-bg;
  padding: 0 0.5rem;
}
