@import 'src/styles/variables.scss';

.link {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25) !important;
  border: 0 !important;
  min-height: 105px;
}

.current {
  background-color: rgba($selected-border, 50%);
}

.upcoming {
  opacity: 60%;
}

.bottleContainer {
  flex-basis: 36px;
}
